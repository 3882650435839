import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "about-0001.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="About" />

            <div className="sm:mx-auto sm:w-2/3">
                <div className="my-12 sm:my-24 text-H1 font-bold font-serif">How does a science-loving book nerd end up a UX designer?</div>

                <div className="flex flex-col sm:flex-row">
                    <div className="w-full sm:w-1/2">
                        <Img fluid={data.file.childImageSharp.fluid} />
                    </div>

                    <div className="sm:w-1/2 sm:pl-4 self-center">
                        <div className="my-4 text-H2 font-bold font-serif text-gondola">tl;dr</div>
                        <p className="my-4">After spending several years working as a facilities professional at companies like Slack and Blend, I made the switch to UX design. I've always admired product design and want nothing more than to be a valuable member of a product team.</p>
                        <div className="flex justify-left">
                            <a href="/Lynn Baxter Résumé.pdf"
                                className="text-btn py-1 px-3 my-8 border border-current text-gondola hover:bg-au_chico hover:text-white hover:border-transparent rounded inline-flex items-center justify-center">
                                <span>Download my resume</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="sm:clear-left mt-8 mb-4 text-H2 font-bold font-serif">My design philosophy</div>
                <p className="my-4">I am interested in people — I find each individual fascinating and complex. In design, my first step is to always find a person who is encountering a difficulty. When we search for a problem to solve, we are really searching for a person or people we want to help. What I’ve found is when you ask a group of people the right questions, you can single out a problem that each and every one of them is trying to solve, or has already solved in a different way. Using that information, a designer has a good base for the creation of a solution to test.</p>
                <p className="my-4">I believe that design must be inclusive to be called good design. It is part of my job as a designer to look at a problem from all possible perspectives, this includes a lens for people from all demographics and abilities.</p>
                <p className="my-4">My primary goal in life is to remain flexible. I would never feel comfortable being a person who is stuck within their own perspective. I use many different methods in my life to ensure that I am seeing the issue from as many angles as possible. It is my firm belief that you can’t learn anything if you think you already know everything.</p>
                <p className="my-4">What drives me are those moments when something I’ve created helps another person be their best selves. If I can help even one person accomplish a goal, I am satisfied. Multiply that to a large scale? Pure joy.</p>

                <div className="sm:clear-left mt-8 mb-4 text-H2 font-bold font-serif">How I work in a team</div>
                <p className="my-4">I’ll be real with you, I married a software engineer. I am well-versed in collaborating with many different skill types in tech due to my background as a facilities manager in startups. I’ve worked well with designers, engineers, data science, customer support, C-level execs, marketing professionals, and so many more. These skills helped me during my time in Lambda’s UX track - each and every project I worked on was a true collaboration that I enjoyed immensely.</p>
                <p className="my-4">As a designer, I like to function as the person who is always asking questions to both clarify my understanding and learn as much as possible from others. Growing a career in tech without having a background in product gave me an education in how to function in a world I knew nothing about. I am so grateful for all my experiences because I’ve learned what questions to ask and who I could get answers from. These environments empowered me to never feel intimidated by any person or any task, but instead to feel endlessly curious.</p>

                <div className="mt-8 mb-4 text-H2 font-bold font-serif">Additional tidbits...</div>
                <ul className="list-none">
                    <li className="my-4">The books I gravitate towards tend to be science fiction, fantasy, and mystery. I adore classics.</li>
                    <li className="my-4">I was a drama kid in school but never got over my crippling stage fright. I still love theater.</li>
                    <li className="my-4">I am a mom to 2 stepsons and 1 extremely adorable little girl named Beatriz.</li>
                    <li className="my-4">I need nature like a cactus needs the sun, I recently started a patio garden that I’m moderately proud of.</li>
                </ul>



            </div>
            
        </Layout >
    )
}
export default IndexPage
